.profileContainer {
    padding: 0.8rem;
    background: rgba(182, 169, 169, 0.3);
    border-radius: 15px;
    backdrop-filter: blur(13px);
    .checkBox {
        height: 22px;
        width: 22px;
        border-radius: 2px;
        background-color: #f3f2f24d;
        .checkIcon{
            position: relative;
            top: -3px;
            left: 2px;
        }
    }
    .avatar {
        height: 70px;
        width: 70px;
        border-radius: 50%;
        background: rgba(228, 228, 228, 0.3);
    }
    .userInfo {
        margin-left: 0.8rem;
        .username {
            font-size: 1rem;
            margin-bottom: 0.1rem;
        }
    }
    span {
        font-size: 0.7rem;
        letter-spacing: 1px;
    }
}
