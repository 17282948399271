.popup {
    background-color: rgba(253, 250, 250, 0.3);
    border-radius: 10px;
    padding: 0.7rem;
    .content {
        padding: 0 1rem;
    }
    p {
        font-size: 0.8rem;
        margin-bottom: 10px;
        font-weight: 100;
    }
    .aboveText{
        font-size: 0.9rem;
        font-weight: 100;
    }
    .whiteBtn{
        width: 100% !important;
    }
}
