.container {
    .title {
        font-size: 0.75rem !important;
    }
    .groupButton {
        button {
            margin-right: 0.6rem !important;
            color: var(--white);
            background-color: rgba(255, 255, 255, 0.3);
            opacity: 1;
            font-size: 0.7rem;
            padding-top: 0.3rem !important;
            padding-bottom: 0.3rem !important;
            border-radius: 2rem !important;
            backdrop-filter: blur(30px);
            &:last-child {
                margin-right: 0 !important;
            }
        }
    }
}
