@import ".../../../../../../theme/mixings.scss";
.mainContainer{
    .contentCardContainer{
            height: 82vh;
                overflow: scroll;
                &::-webkit-scrollbar {
                    display: none !important;
                }
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 2.5rem;
            .cardContainer{
                height: 10rem;
                width: 80%;
                background-color: #c1b4b440;
                border-radius: 0.8rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 0.8rem;
                cursor: pointer;
                img{
                    height: 4.5rem;
                    width: 4.5rem;
                    margin: 1rem 0rem;
                }
            }
        }



        .arrowStyle{
            height: 2.5rem;
            width: 2.5rem;
            fill: #fff;
            cursor: pointer;
        }

        .carouselContainer {
            height: 80vh;
                overflow: scroll;
                &::-webkit-scrollbar {
                    display: none !important;
                }


            overflow-x: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            // .carouselSlideContainer{
            //     width: 84%;
            //     .carouselSlide {
            //         width: 100%;
            //     }
            // }
            .carouselSlider {
                padding-left: 150px;
            }
            -webkit-overflow-scrolling: touch;
            &::-webkit-scrollbar {
                display: block;
                height: 95px;
                background-color: transparent;
            }
        }
        .singleCarouselContainer {
            padding-left: 0 !important;
            justify-content: center;
        }
        .clientReview {
            font-size: 1.2rem !important;
            padding-left: 3rem !important;
            padding-right: 3rem !important;
            @include for-size(phone-only){
                width: 95%;
                margin-bottom: 1rem;
            }
        }
        @include for-size(phone-only) {
            // .carouselSlideContainer{
            //     width: 89% !important;
            // }
        }
}