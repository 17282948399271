.notificationContainer {
    padding: 0.8rem;
    background: rgba(243, 242, 242, 0.3);
    border-radius: 15px;
    backdrop-filter: blur(13px);
    .avatar {
        height: 55px;
        width: 55px;
        border-radius: 50%;
        background: rgba(250, 250, 250, 0.8);
        .icon {
            fill: var(--black);
            height: 35px;
            width: 35px;
        }
    }
    .rightContainer {
        margin-left: 0.8rem;
        .text {
            font-size: 10px !important;
            font-weight: unset !important;
            letter-spacing: 1px;
        }
        .heading {
            margin: 3px 0;
            font-size: 1rem !important;
        }
    }
}
