@import "../../../../theme/mixings.scss";

.card {
    display: flex;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    opacity: 1;
    align-items: center;
    backdrop-filter: blur(30px);
    padding: 0 30px 30px 30px !important;
    justify-content: space-between;
    border: 1px solid #ffffff;
    height: 100%;
    flex-direction: column;
    @include for-size(tablet-portrait-up) {
        width: 60%;
        margin: auto;
    }
    .description {
        max-height: 39vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .cardImage {
        width: 120px;
        background-color: black;
    }
    .recommended {
        background: white;
        padding: 5px 50px;
        color: #444444;
        border-radius: 0px 0px 25px 25px;
    }
    .whiteButtonArrow {
        @include for-size(phone-only) {
            display: block !important;
            position: absolute;
            right: 11px;
            width: 32px;
        }
    }
}
