@import "../../../theme/mixings.scss";

@mixin card-layout {
    padding: 0.8rem;
    background: rgba(243, 242, 242, 0.3);
    border-radius: 10px;
}
.settingsContainer {
    padding-top: 2.6rem;
    .leftPortion {
        border-right: 2px solid var(--white);
        .leftContainer {
            width: 65%;
            height: 93vh;
            overflow-y: scroll;
            margin: auto;
            flex-direction: column;
            padding-bottom: 5rem;
            &::-webkit-scrollbar {
                display: none;
            }
            .profileContainer {
                @include card-layout;
                @include for-size(desktop-up) {
                    height: 13vh;
                }
                backdrop-filter: blur(13px);

                .avatar {
                    height: 60px;
                    width: 60px;
                    border-radius: 50%;
                    background: rgba(228, 228, 228, 0.3);
                }
                .userInfo {
                    margin-left: 0.8rem;
                    .username {
                        font-size: 0.6rem !important;
                        margin-bottom: 0.1rem;
                        @include for-size(big-desktop-up){
                            font-size: 1.2rem;
                        }
                    }
                }
                span {
                    font-size: 0.7rem;
                    letter-spacing: 1px;
                }
                .coinsValue{
                    font-size: 1.2rem;
                }
            }
            .settingsCard {
                width: 47%;
                margin-bottom: 0.5rem;
            }
            .promoCard{
                @include card-layout;
                backdrop-filter: blur(13px);
                .title {
                    font-size: 1rem;
                    margin-bottom: 0.1rem;
                }

                .description {
                    font-size: 0.7rem;
                    font-weight: 100;
                    margin-top: 5px;
                }

                .socialIconBox {
                    gap: 2vw;
                    .icon {
                        @include card-layout;
                    }
                }
                @include for-size(desktop-up){
                    height: 60px;
                }
                @include for-size(big-desktop-up){
                    height: 10vh;
                    .title {
                        font-size: 1.2rem;
                    }
                    
                    .description {
                        font-size: 0.9rem;

                    }
                }
                @include for-size(phone-only) {
                    .title {
                        font-size: 1.2rem !important;
                    }
                }
            } 
            .card {
                @include card-layout;
                backdrop-filter: blur(13px);
                .title {
                    font-size: 1rem;
                    margin-bottom: 0.1rem;
                }

                .description {
                    font-size: 0.7rem;
                    font-weight: 100;
                    margin-top: 5px;
                }

                .socialIconBox {
                    gap: 2vw;
                    .icon {
                        @include card-layout;
                    }
                }
                @include for-size(desktop-up){
                    height: 115px;
                }
                @include for-size(big-desktop-up){
                    height: 17vh;
                    .title {
                        font-size: 1.2rem;
                    }
                    
                    .description {
                        font-size: 0.9rem;

                    }
                }
                @include for-size(phone-only) {
                    .title {
                        font-size: 1.2rem !important;
                    }
                }
            }
            @include for-size(phone-only) {
                width: 100%;
                padding-bottom: 10rem;
            }
        }
        @include for-size(phone-only) {
            border: none;
        }
        @include for-size(tablet-portrait-up) {
            border: none;
        }
    }
    .rightContainer {
        width: 90%;
        @include for-size(phone-only) {
            position: relative;
            width: 100%;
            .backArrow {
                position: absolute;
                top: -0.6rem;
                z-index: 10;
            }
        }
        @include for-size(tablet-portrait-up) {
            width: 70%;
            margin: auto;
            .backArrow {
                position: absolute;
                left: 3rem;
            }
        }
    }
    .promoCodeTitle{
        font-size: 0.8rem;
        letter-spacing: 0.3px;
        @include for-size(big-desktop-up){
            font-size: 1rem;
        }
    }
}
