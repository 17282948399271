@import "../../../../theme/mixings.scss";

.signupBox {
    overflow: hidden;
    border: 3px solid #ffffff;
    border-radius: 30px;
    opacity: 1;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    .fieldWidth {
        width: 63%;
    }
    .description {
        font-size: 0.8rem;
        font-weight: 100;
        margin-bottom: 0.6rem !important;
        width: 57%;
        @include for-size(tablet-portrait-up) {
            width: 57% !important;
            margin-bottom: 1rem !important;
        }
    }
    .chatIcon {
        height: 20px;
        width: 20px;
    }
    .labelText {
        font-size: 0.9rem;
    }
    .second {
        font-size: 1.5rem !important;
        > span {
            font-size: 0.9rem;
            position: relative;
            left: -3px;
        }
    }
    .phoneInput {
        width: 100%;
        opacity: 1;
        border: 0;
        color: white;
        border-radius: 18px;
        background-color: rgba(255, 255, 255, 0.3);
        &::placeholder {
            color: black;
        }
    }
    .submitButton {
        width: 60%;
        padding-top: 3px !important;
        padding-bottom: 3px !important;
        font-size: 1.2rem !important;
    }
    .skip{
        background-color: transparent !important;
        border: 1px solid  var(--white) !important;
        color:  var(--white) !important;
    }
    .otpFieldBox {
        width: 64%;
        margin: auto;
        @include for-size(tablet-portrait-up) {
            width: 90%;
        }
        @include for-size(phone-only) {
            width: 100%;
        }
        .emailInput {
            border-radius: 1.5rem;
            color: var(--black) !important;
            background-color: rgba(252, 252, 252, 0.856);
            &::placeholder {
                color: #707070 !important;
            }
        }
        .emailContainer {
            width: 100%;
        }
    }
    @include for-size(phone-only) {
        margin-bottom: 3rem;
        .submitButton,
        .fieldWidth {
            width: 100%;
        }
    }
    @include for-size(tablet-portrait-up) {
        margin-bottom: 3rem;
        .submitButton,
        .fieldWidth {
            width: 90%;
        }
    }
    @include for-size(big-desktop-up) {
        .title {
            font-size: 1.6rem !important;
            margin-top: 1.5rem !important;
        }
        .otpFieldBox {
            padding: 1.2rem 0 !important;
        }
        .submitButton {
            height: 50px !important;
            margin-bottom: 2rem !important;
            width: 58%;
        }
        .phoneInput {
            height: 50px;
            border-radius: 30px;
        }
        .resendTextBox {
            margin: 1rem 0;
            .labelText {
                font-size: 1rem;
            }
        }
        .second {
            margin-bottom: 1rem !important;
            font-size: 1.6rem;
            > span {
                font-size: 1rem;
                position: relative;
                left: -4px;
            }
        }
        .phoneNumberLabel {
            margin-bottom: 1rem !important;
        }
        .description {
            font-size: 1rem;
            margin-bottom: 1.9rem !important;
            margin-top: 1rem !important;
        }
    }
}

@include for-size(big-desktop-up) {
    .mainHeading {
        font-size: 2rem !important;
        margin-bottom: 0.9rem !important;
    }
}

.inputError {
    color: red;
    font-size: 14px;
    display: flex;
    margin-left: 10px;
    margin-top: -10px;
}
