.instagramBtn{
    background: transparent;
    border: 0px;
    position: relative;
    left: 8px;
  }
  .socialIcon{
    cursor: pointer;
    width: 50px;
    height: 50px;
    padding: 10px;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.3);
    object-fit: scale-down;
  }