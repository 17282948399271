@import "../../../theme/mixings.scss";

.mainContainer {
    @include full-screen-background-image("../../../assets/images/homeModule/reasonPageBg.png");
    min-height: 100vh;
    @include for-size(tablet-portrait-up) {
        h2 {
            justify-content: center;
            display: flex;
        }
    }
}
