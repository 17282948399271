@import '../../../../theme/mixings.scss';
.container {
    padding: 0.7rem;
    background: rgba(243, 242, 242, 0.3);
    border-radius: 10px;
    backdrop-filter: blur(13px);
    span{
        font-size: 0.8rem;
        letter-spacing: 0.3px;
        @include for-size(big-desktop-up){
            font-size: 1rem;
        }
    }
    .coinsValue{
        font-size: 1.2rem;
        margin-top: 8px;
    }
    .coinsDetail{
        font-size: 0.7rem;
        margin-left: 5px;
    }
    @include for-size(big-desktop-up){
        height: 13vh;
    }
}
