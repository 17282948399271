@import "../../../theme/mixings.scss";
@mixin common-control-btn($wh) {
    width: $wh;
    height: $wh;
}
.container {
    width: 100%;
    .waveSurfer {
        flex: 1;
    }
    .buttonContainerBox {
        .playPauseTab {
            @include common-control-btn(30px);
        }
        .playPauseTab {
            @include common-control-btn(30px);
        }
        .pauseBox{
            @include common-control-btn(40px);
        }
        .playPauseBox {
            @include common-control-btn(50px);
        }
    }
    .timeFont {
        font-size: 0.8rem;
    }
    .currentTimeSingle {
        z-index: 10;
        position: absolute;
        left: 39px;
        bottom: -44px;
        @include for-size(phone-only) {
            bottom: -41px;
            left: 30px;
        }
    }
    .currentTimeBoost{
        bottom: 15px;
        @include for-size(big-desktop-up){
            bottom: 44px;
        }
        @include for-size(tablet-portrait-up){
            bottom: 9px;
        }
    }
    .timer {
        background: rgba(14, 11, 11, 0.25);
        backdrop-filter: blur(20px);
        border-radius: 10px;
        padding: 4px 15px 4px 15px;
        height: 30px;
        width: 70px;
        span {
            font-size: 0.8rem;
        }
    }
}
