.dialog{
    backdrop-filter: blur(3px);
}
.container{
    font-weight: 100 !important;
    margin-bottom: 15px;
    
    .title{
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 100;
        .closeIcon{
            position: absolute;
            right: 20px;
            cursor: pointer;
        }
    }
    .inputField{
        margin-left: 20px;
        margin-right: 20px;
        .inputFieldColor{
            background-color: rgba(0, 0, 0, 0.6) !important;
            backdrop-filter: blur(1px) !important;
            border: 1px solid rgb(255, 255, 255) !important
        }
    }

    .createPlaylist {
        height: 50px;
        background-color: rgba(0, 0, 0, 0.6) !important;
        backdrop-filter: blur(1px) !important;
        border-radius: 10px;
        margin-left: 20px;
        margin-right: 20px;
        border: 1px solid rgb(255, 255, 255)
    }
    .buttonStyle{
        position: relative;
        right: 20px;
    }
}
