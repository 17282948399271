.accordian {
    background: rgba(243, 242, 242, 0.3) !important;
    border-radius: 10px !important;
    p {
        font-size: 0.8rem !important;
        font-weight: 100 !important;
        letter-spacing: 1px;
        font-family: inherit;
    }
}
