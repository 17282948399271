.taskbar {
    background-color: rgba(253, 250, 250, 0.1);
    border-radius: 10px;
    padding: 1rem;
    .title {
        flex: 1;
        font-weight: 100;
        font-size: 0.9rem;
    }
    .instructions {
        font-weight: 100;
    }
    .lock {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        opacity: 0.9;
    }
    .description {
        font-weight: 100 !important;
        font-size: 0.73rem !important;
    }
    .audioBox {
        border: 1px solid var(--white);
        padding: 1rem;
        border-radius: 10px;
        .barContainer {
            width: 90%;
            position: relative;
            top: 12px;
            .dot {
                height: 10px;
                width: 10px;
                border-radius: 50%;
                background-color: var(--white);
                position: relative;
                top: -5px;
            }
            .bar {
                width: 100%;
                height: 2px;
                background-color: var(--white);
            }
            .timer {
                font-size: 0.6rem;
            }
        }
    }
    .readLessonBox {
        .readLessonTitle {
            font-size: 0.8rem !important;
            a {
                color: var(--white);
            }
        }
        .activiesButton {
            font-size: 0.9rem !important;
            padding: 5px 20px !important;
            a {
                color: var(--black);
            }
        }
    }
    .emojiContainer {
        gap: 5px;
        .emojiBox {
            align-items: center;
            width: 50px;
            span {
                text-align: center;
                font-size: 0.6rem;
                font-weight: 100;
                margin-top: 4px;
                letter-spacing: 0.7px;
            }
        }
    }
}
.disabled {
    pointer-events: none;
}
