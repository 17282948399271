@import ".../../../../../../theme/mixings.scss";
.carouselSlideContainer{
    width: 83%;
    .carouselSlide {
        width: 100%;
    }
}
@include for-size(phone-only) {
    .carouselSlideContainer{
        width: 89% !important;
    }
}