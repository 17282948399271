.button{
    height: 40px;
    color: var(--black);
}
del{
    color: var(--black);
}
.disabled{
    color: rgb(105, 104, 104) !important;
}
