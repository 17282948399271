@import "../../../theme/mixings.scss";

.container {
    padding-left: 1.4rem !important;
    overflow-y: scroll;
    height: calc(100vh - 150px);
    &::-webkit-scrollbar {
        width: 10px;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.3);
        box-shadow: 0 8px 32px 0 rgba(39, 39, 41, 0.37);
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(2, 0, 0, 0.3);
        border-radius: 10px;
    }

    @include for-size(phone-only) {
        &::-webkit-scrollbar {
            display: none !important;
        }
    }
    @include for-size(tablet-portrait-up) {
        &::-webkit-scrollbar {
            display: none !important;
        }
    }
    .playListCard {
        width: 18%;
        margin-right: 1rem;
        @include for-size(phone-only) {
            width: 45%;
            margin-right: 0.8rem;
        }
        @include for-size(tablet-portrait-up) {
            width: 30%;
        }
    }
}
.spiner{
    align-self: center;
    height: 170px;
}
.emptyList{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 170px;
    width: 100%;
}
