* {
    color: #fff;
    font-family: "Comfortaa";
}
a {
    text-decoration: none;
}
html,
body {
    height: 100%;
}
body::-webkit-scrollbar {
    width: 10px; 
    background: rgba(75, 74, 74, 0.1);
}
body::-webkit-scrollbar-thumb {
    background: rgba(24, 23, 23, 0.5);
}
