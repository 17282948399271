@import "../../../theme/mixings.scss";

.mainContainer {
    background: rgba(253, 253, 253, 0.2) !important;
    border-radius: 30px 30px 0 0 !important;
    backdrop-filter: blur(15px);
    .bottomContainer {
        height: 76px;
        background-color: transparent !important;
        width: 70%;
        margin: auto;
        .navItem {
            span {
                height: 30px;
                color: var(--black) !important;
                @include for-size(phone-only) {
                    font-size: 0.7rem !important;
                }
            }
        }
        .activeItem {
            span {
                color: var(--white) !important;
            }
        }
    }
}
