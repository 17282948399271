@import "../../../theme/mixings.scss";

.mainContainer {
    @include full-screen-background-image("");
    min-height: 100vh;
    padding: 4rem 0;
    padding-bottom: 1rem;

    .welcome {
        font-size: 2.5rem !important;
        font-weight: bolder;
    }
    .videoContainer{
        width: 90%;
    }
    .leftMainContainer{
        display: flex ;
        justify-content: flex-end;     
        @include for-size(tablet-portrait-up) {
            justify-content: center !important;
        }
        @include for-size(phone-only) {
            justify-content: center !important;
        }
        .leftContainer {
            // width: 300px;
            .thumnailContainer {
                height: 97%;
                width: 100%;
                position: relative;
                img {
                    height: 100%;
                    border-radius: 16px;
                    width: 100%;
                }
                .playbutton{
                    position: absolute;
                    left: 32%;
                    top:43%
                }
            }
            .monoLogo {
                height: 300px;
                width: 300px;
            }
          
            .imageContainer {
                height: 250px;
                background-color: rgb(208 204 204 / 43%);
                border-radius: 50%;
                width: 250px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    height: 60%;
                    width: 60%;
                }
            }
    
            @include for-size(tablet-portrait-up) {
                width: unset;
                align-items: center;
            }
    
            @include for-size(phone-only) {
                width: unset;
                align-items: center;
            }
        }

    }

    .rightPortion {
        @include for-size(tablet-portrait-up) {
            justify-content: center !important;
        }

        @include for-size(phone-only) {
            justify-content: center !important;
            padding: 0 !important;
        }

        .descriptionContainer {
            width: 53%;
            // text-align: center;
            margin: auto;
            margin-top: 3rem;
            .description {
                font-size: 1.4rem;
                @include for-size(phone-only){
                    font-size: 1.4rem !important;
                }
            }

        }
    }

    .spiner {
        height: 50vh;
    }

    .buttonContainer {
        margin-right: 5vw;

        @include for-size(big-desktop-up) {
            margin-right: 13vw;
        }

        @include for-size(tablet-portrait-up) {
            justify-content: center !important;

            .submitButton {
                width: 50%;
            }
        }

        @include for-size(phone-only) {
            margin-right: 0;
            margin-top: 3rem;
        }

        @include for-size(tablet-portrait-up) {
            margin-right: 0;
            margin-top: 4rem;
        }

        // @include for-size(tablet-landscape-up) {
        //     justify-content: center !important;
        //     .submitButton {
        //         width: 50%;
        //     }
        // }
    }
}