@import '../../../theme/mixings.scss';

@mixin contentlayout {
    overflow: scroll;
    padding-bottom: 2rem;
    padding-bottom: 5rem;
    @include for-size(phone-only) {
        padding-bottom: 10rem;
    }
    @include for-size(tablet-portrait-up){
        padding-bottom: 8rem;
    }
    &::-webkit-scrollbar {
        display: none !important;
    }
}

.contentContainer {
    height: 85vh;
    @include contentlayout;
}
.journalPopupContainer {
    height: 60vh;
    @include contentlayout;
}
