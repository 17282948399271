@import "../../../theme/mixings.scss";

.successBox{
    padding: 1.5rem;
    width: 40%;
    background: rgba(243, 242, 242, 0.3);
    border-radius: 10px;
    @include for-size(phone-only){
        width: 90%;
    }
}