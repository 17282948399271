@import "../../../../theme/mixings.scss";

.card {
    display: flex;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    opacity: 1;
    width: 90%;
    margin: auto;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(30px);
    flex-direction: column;
    max-height: 22vh;
    @include for-size(phone-only) {
        width: 100%;
    }
    .cardImage {
        fill: var(--white);
        height: 11vh;
        width: 11vh;
    }
}
.selectedCard {
    background-color: var(--white);
    .cardImage {
        fill: #707070;
        filter: invert(44%) sepia(0%) saturate(0%) hue-rotate(191deg) brightness(97%) contrast(88%);
        opacity: 1 !important;
        height: 11vh;
        width: 11vh;
    }
    > h5 {
        color: #707070;
    }
}
