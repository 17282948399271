.indicatorIcon{
    width: 5px;
    height: 5px;
    background-color: black;
    margin: 5px;
    border-radius: 6px;
}
.slide{
    margin: 0 10px 0 10px;
    img{
        width: 40%;
    }
}