.sidebar {
    > div {
        height: 95%;
        margin: 1rem;
        border: 2px solid var(--white);
        border-radius: 15px;
        padding: 1rem;
        background: rgba(0, 0, 0, 0.3) !important;
        backdrop-filter: blur(15px);
    }
    .navlogo {
        height: 80px;
        width: 100px;
    }
    .listItem {
        // background-color: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(8px);
        border-radius: 10px;
        margin-bottom: 0.8rem;
        padding: 0.2rem 0.6rem;
        > div {
            min-width: 30px !important;
        }
        span {
            font-size: 14px !important ;
        }
        &:hover {
            background-color: rgba(255, 255, 255, 0.1) !important;
        }

        .NoticationCount{
            background-color: red;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            display: flex;
            justify-content: center;
            position: absolute;
            left: 20px;
            top: 7px;
            span{
                font-size: 8px !important;
            }
        }
    }
    .active {
        border: 1px solid white !important;
    }
    .bottomToolbar {
        position: absolute;
        bottom: 3%;
        padding: 0px;
        width: 88%;
        .profileIconBox {
            height: 55px;
            width: 55px;
            border: 1px solid;
            border-radius: 50%;
            img {
                height: 100%;
                width: 100%;
            }
        }
        .profileBox {
            margin-left: 4px !important;
            h5 {
                font-size: 1rem;
                margin-bottom: 0;
                width: 140px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            span {
                font-size: 0.6rem;
                width: 140px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
}
