@import "../../../theme/mixings.scss";

.container {
    width: 70%;
    height: 82vh;
    overflow-y: scroll;
    margin: auto;
    flex-direction: column;
    padding-bottom: 5rem;
    @include for-size(tablet-portrait-up) {
        padding-bottom: 10rem;
    }
    @include for-size(phone-only) {
        width: 90%;
        padding-bottom: 10rem;
    }

    &::-webkit-scrollbar {
        display: none;
    }
    .contentContainer {
        width: 90%;
        margin: auto;
        @include for-size(phone-only) {
            width: 100%;
        }
        .journalButton{
            background-color: #d2d2d285 !important;
            border-radius: 3px !important;
            color: white !important;
            padding: 8px 8px !important;
                    }
        .taskbar {
            background-color: rgba(253, 250, 250, 0.1);
            border-radius: 10px;
            padding: 1rem;
            .title {
                flex: 1;
                font-weight: 100;
                font-size: 0.9rem;
            }
            .instructions {
                font-weight: 100;
            }
            .lock {
                height: 30px;
                width: 30px;
                border-radius: 50%;
                opacity: 0.9;
            }
            .description {
                font-weight: 100 !important;
                font-size: 0.73rem !important;
            }
            .audioBox {
                border: 1px solid var(--white);
                padding: 1rem;
                border-radius: 10px;
                .barContainer {
                    width: 90%;
                    position: relative;
                    top: 12px;
                    .dot {
                        height: 10px;
                        width: 10px;
                        border-radius: 50%;
                        background-color: var(--white);
                        position: relative;
                        top: -5px;
                    }
                    .bar {
                        width: 100%;
                        height: 2px;
                        background-color: var(--white);
                    }
                    .timer {
                        font-size: 0.6rem;
                    }
                }
            }
            .readLessonBox {
                .readLessonTitle {
                    font-size: 0.8rem !important;
                }
                .activiesButton {
                    font-size: 0.9rem !important;
                    padding: 5px 20px !important;
                }
            }
        }
        p {
            font-size: 0.8rem;
            margin-bottom: 10px;
            font-weight: 100;
        }
        .popup {
            background-color: rgba(253, 250, 250, 0.3);
            border-radius: 10px;
            padding: 0.7rem;
            .content {
                padding: 0 1rem;
            }
        }
    }
    .bottomTimer {
        position: absolute;
        bottom: 0;
        width: 33%;
        z-index: 999;
        @include for-size(phone-only){
            width: 84%;
        }
    }
}
.googFormIFrame{
    height: 88vh;
    width: 100%;
}
.backArrow{
    position: relative;
    top:-2.9rem;
}