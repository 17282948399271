.mainContainer{
    height: auto;
    width: auto;
    background-color: #CE9878;
    display: flex;
    justify-content: center;

    .leftPortionContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .content1{
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 25px 0px;
            .badgeSection{
                margin-bottom: 35px;
                .badge{
                    height: 60px;
                    width: 280px;
                }
            }
            p{
                font-size: 20px;
                font-weight: bold;
                line-height: 10px;
                position: relative;
                span{
                    position: absolute;
                    left: 75px;
                    top: 26px;
                    font-size: 30px;
                    font-weight: bold;
                }
            }
            .title{
                color: #CE9878;
                margin-bottom: 55px;
                z-index: 200;
            }
        }
    }
    .rightPortionContainer{
        .rightPortionImage{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: end;
            overflow: hidden;
            height: 100vh;
            border-radius: 15px;
            max-width: 98vw;
            .webImage{
                height: 98vh;
                margin: 7px 0px;
            }
            .content{
                position: absolute;
                .buttonContainer{
                    .buttonStyle{
                        padding: 20px 140px !important;
                        font-size: 1.5rem !important;
                    }
                }
            }
        }
    }
}

.ipadView{
    overflow: hidden;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: end;
    .ipadImage{
        width: 100%;
        height: 100vh;
    }
    .content{
        position: absolute;
        .content1{
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 30px 0px;
            font-size: 24px;
            .badgeSection{
                margin-bottom: 50px;
            }
            p{
                font-size: 28px;
                font-weight: bold;
                line-height: 25px;
                position: relative;
                span{
                    position: absolute;
                    left: 90px;
                    top: 22px;
                    font-size: 40px !important;
                    font-weight: bold;
                }
            }
            .title{
                color: #CE9878;
                margin-bottom: 55px;
                z-index: 200;
            }
        }
        .buttonContainer{
            display: flex;
            justify-content: center;
            align-items: center;
            .buttonStyle{
                padding: 30px 170px !important;
                font-size: 2rem !important;
            }
        }
    }
}
.mobileView{
    overflow: hidden;
    height: 100vh;
    position: relative;
    display: flex;
        justify-content: center;
        align-items: end;
    .ipadImage{
        width: 100%;
        height: 100vh;
    }
    .content{
        position: absolute;
        .content1{
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 30px 0px;
            font-size: 24px;
            .badgeSection{
                margin-bottom: 35px;
                .badge{
                    height: 50px;
                    width: 200px;
                }
            }
            p{
                font-size: 18px;
                font-weight: bold;
                line-height: 10px;
                position: relative;
                span{
                    position: absolute;
                    left: 50px;
                    top: 22px;
                    font-size: 22px !important;
                    font-weight: bold;
                }
            }
            .title{
                color: #CE9878;
                margin-bottom: 55px;
                z-index: 200;
            }
            span{
                font-size: 15px !important;
            }
        }
        .buttonContainer{
            display: flex;
            justify-content: center;
            align-items: center;
            .buttonStyle{
                padding: 20px 130px !important;
                font-size: 1.2rem !important;
            }
        }
    }
}