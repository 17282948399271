@import "../../../theme/mixings.scss";
.outletContainer {
    padding-left: 2rem;
}
.appBarContainer {
    z-index: 10;
    height: 100px;
    margin: 0rem 5rem 0rem 2rem;
    align-items: center;
    .title {
        margin-left: 1.7rem !important;
    }
    .rightPortion {
        width: 40%;
    }
    .input {
        border-radius: 20px;
        width: 100%;
        border: none !important;
        border: 0;
        height: 40px;
        background: rgba(216, 216, 216, 0.2);
        backdrop-filter: blur(10px);
        margin-right: 2rem;
        .inputAdorment {
            margin-left: 1rem;
        }
        input::placeholder {
            color: var(--white) !important;
        }
        &::before {
            border: none !important;
        }
        @include for-size(tablet-portrait-up) {
            width: 70%;
        }
        @include for-size(phone-only) {
            width: 100%;
        }
    }
    @include for-size(tablet-portrait-up) {
        margin: 0rem 1rem;
        .rightPortion {
            width: 50%;
        }
    }
    @include for-size(phone-only) {
        flex-direction: column !important;
        align-items: flex-start;
        margin: 0rem 2rem;
        .rightPortion {
            width: 100% !important;
        }
        padding-top: 1rem;
        margin-bottom: 1rem;
    }
}
.boostContainer {
    height: calc(100vh - 100px);
    overflow-y: scroll;
    padding-bottom: 5rem;
    @include for-size(phone-only) {
        padding-bottom: 10rem;
    }
    @include for-size(tablet-portrait-up) {
        padding-bottom: 14rem;
    }
    &::-webkit-scrollbar {
        width: 10px;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.3);
        box-shadow: 0 8px 32px 0 rgba(39, 39, 41, 0.37);
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(2, 0, 0, 0.3);
        border-radius: 10px;
    }

    @include for-size(phone-only) {
        &::-webkit-scrollbar {
            display: none !important;
        }
    }
    @include for-size(tablet-portrait-up) {
        &::-webkit-scrollbar {
            display: none !important;
        }
    }
    .contentContainer {
        // background-color: rgba(255, 255, 255, 0.1);
        width: 60%;
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(15.5px);
        border-radius: 15px;
        .mediaPlayerBox {
            height: 132px;
            border-radius: 15px 15px 0 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @include for-size(desktop-up) {
                height: 23vh;
            }
            .mediaPlayer {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 60%;
            }
            .timer {
                height: 30px;
                width: 70px;
                background: rgba(14, 11, 11, 0.25);
                backdrop-filter: blur(20px);
                border-radius: 10px;
                padding: 4px 15px 4px 15px;
                span {
                    font-size: 0.8rem;
                }
            }
        }
        @include for-size(tablet-portrait-up) {
            width: 70%;
        }
        @include for-size(phone-only) {
            width: 100% !important;
        }
    }
    .playlist {
        display: grid;
        .playListCarousel {
            margin-left: 2%;
            .playListCard {
                margin-right: 1.2rem;
            }
            &::-webkit-scrollbar {
                display: none !important;
            }
        }
        .spiner{
            align-self: center;
            height: 170px;
        }
        .emptyList{
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 170px;
        }
    }
    .spiner{
        align-self: center;
        height: 170px;
    }
    .emptyList{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 170px;
        width: 100%;
    }
    .categoriesContainer {
        padding-left: 1.4rem !important;
        overflow-x: hidden;
        .playListCard {
            width: 18%;
            margin-right: 1rem;
            @include for-size(phone-only) {
                width: 45%;
                margin-right: 0.8rem !important;
            }
            @include for-size(tablet-portrait-up) {
                width: 30%;
            }
        }
    }
}
