@import "../../../theme/mixings.scss";
.homeButton{
    position: absolute;
    top: 71%;
    padding: 8px;
    border-radius: 10px;
    background-color: rgb(134, 134, 134);
    a{
        color: #fff;
        &:hover{
            color: black;
        }
        @include for-size(phone-only){
            font-size: 10px;
        }
    }
}